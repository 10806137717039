.visibilityToggle {
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: 0;
  inline-size: 2.75rem;
  block-size: 100%;
  background: transparent !important;
  color: rgb(1 42 75 / 50%);
}

.visibilityToggleIcon {
  block-size: 1.5rem;
  inline-size: 1.5rem;
}
