@import 'styles/mixins';

.root {
  display: flex;
  flex: 1;
  flex-direction: column;

  .normal {
    background-color: transparent;
  }

  .finish {
    background-color: var(--palette-success-background);
  }
}

.spaced {
  padding-block: 16px;

  @include container-full-width;

  @include sm {
    padding-block: 16px;
  }

  @include md {
    padding-block: 40px;
  }

  &.horizontal {
    padding-block: 0;
  }
}
