@import 'styles/mixins';

.root {
  background-color: var(--palette-error-background);
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  block-size: 100vh;
  gap: 2rem;
  max-inline-size: $screen-sm-min;
  margin-block: 0;
  margin-inline: auto;
}

.heading {
  margin-block-end: 0;
}
