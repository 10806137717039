@import 'styles/mixins';

.root {
  display: flex;
  flex-direction: column;

  @include md {
    flex-direction: column-reverse;
  }
}

.buttonWrapper {
  flex-direction: row;
  justify-content: center;

  @include sm {
    justify-content: space-between;
  }
}

.signUp {
  text-align: center;

  @include md {
    text-align: end;
    margin-block: 0;
    margin-inline: 1rem;
  }

  @include lg {
    margin-block: 0;
    margin-inline: 3rem;
  }

  @include xl {
    inline-size: 70rem;
    margin-block: 0;
    margin-inline: auto;
  }

  a {
    text-transform: uppercase;
    font-weight: bold;
    margin-inline-start: 0.5rem;
  }
}

.forgotPassword {
  vertical-align: middle;

  // line-height: 5.438rem;
}

.reportProblemWrapper {
  flex: 1 0 auto;
  align-self: center;
  display: flex;
  flex-direction: row;

  .reportProblem {
    align-self: flex-end;
  }
}
