.input {
  display: block;
  inline-size: 100%;
  padding-block: 0.375rem;
  padding-inline: 0.75rem;
  font-size: 1rem;
  line-height: 1.42857;
  color: var(--palette-text-primary);
  background-color: var(--palette-background-secondary);
  background-image: none;
  border: 1px solid var(--palette-divider);
  border-radius: var(--dimensions-border-radius-default);
  box-shadow: var(--shadow-default);
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;

  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: var(--shadow-hover);
  }
}
