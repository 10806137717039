.type-error {
  --error-alert-background: var(--palette-error-background);
  --error-alert-color: var(--palette-error-main);
  --error-alert-text-color: var(--palette-error-contrast-text);
}

.type-warning {
  --error-alert-background: var(--palette-warning-background);
  --error-alert-color: var(--palette-warning-main);
  --error-alert-text-color: var(--palette-warning-contrast-text);
}

.type-success {
  --error-alert-background: var(--palette-success-background);
  --error-alert-color: var(--palette-success-main);
  --error-alert-text-color: var(--palette-success-contrast-text);
}

.type-info {
  --error-alert-background: var(--palette-info-background);
  --error-alert-color: var(--palette-info-main);
  --error-alert-text-color: var(--palette-info-contrast-text);
}

.root {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  background: var(--error-alert-background);
  border-radius: var(--dimensions-border-radius-default);
  border: 1px solid var(--error-alert-color);
  padding: 1.5rem;
  box-shadow: 0 10px 20px 0 #0000000d;
}

.icon {
  flex: 0 0 1.25rem;
  color: var(--error-alert-color);
  font-size: 1.25rem;
  padding-block-start: 0.125rem;
}

.message {
  flex: 1 1 auto;

  .title {
    line-height: 1.5rem;
    color: var(--error-alert-color);
    margin: 0;
    text-transform: uppercase;
  }

  .subtitle {
    color: var(--error-alert-text-color);
    margin-block-end: 0;
  }
}

.close {
  display: block;
  flex: 0 0 auto;
  inline-size: 1.5rem;
  min-inline-size: 1.5rem;
  block-size: 1.5rem;
  font-size: 1.5rem;
  color: var(--error-alert-text-color);
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
}
